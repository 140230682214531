#miniprofile-options {
    @apply: inline-flex;
}

#miniprofile-options:not(.show) {
    @apply hidden;
}

#dh-sidenavbar-float {
    @apply absolute;
}

@media (max-width: 1079px) {
    #dh-sidenavbar {
        @apply fixed z-20;
        transition: transform 0.3s ease-in-out;
    }

    #dh-sidenavbar:not(.show) {
        @apply -translate-x-[100%];
    }

    #dh-sidenavbar.show+#dh-sidenavbar-float {
        @apply bg-versich-dark-blue/10 fixed inset-0 z-10;
    }
}