.slick-list {
    border-radius: 11px;
    overflow: hidden;
}

.slick-dots ul.slick-dots-wrapper {
    @apply flex items-center justify-center;
}

.slick-dots ul.slick-dots-wrapper li {
    @apply inline-flex items-center justify-center w-[14px] h-[14px] m-0;
}

.slick-dots ul.slick-dots-wrapper li:not(.slick-active) button:hover:after {
    @apply bg-versich-light-blue;
}

.slick-dots ul.slick-dots-wrapper li.slick-active button {
    @apply bg-[#DDEDFF] w-[15px] h-[15px] after:bg-versich-light-blue after:w-[9px] after:h-[9px];
}

.slick-dots ul.slick-dots-wrapper button {
    @apply bg-[#A8D2FF] inline-flex items-center justify-center w-2.5 h-2.5 rounded-full before:content-none relative;
}

.slick-dots ul.slick-dots-wrapper li button:after {
    @apply content-[""] w-1 h-1 absolute z-10 rounded-full;
}