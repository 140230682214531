.conversation.message_received+.conversation.message_sent,
.conversation.message_sent+.conversation.message_received {
    @apply mt-3
}

.custom_scroll .rcs-outer-container,
.custom_scroll .rcs-inner-container,
.custom_scroll .rcs-inner-container>div {
    @apply h-full;
}

.custom_scroll .rcs-inner-handle {
    @apply bg-versich-darktext-color/65;
}