.card {
    @apply bg-white flex flex-col h-auto mx-auto p-5 pt-10 relative isolate rounded-lg overflow-hidden;
    -webkit-box-shadow: 4px 4px 70.6px 0px #EDEDED40;
    box-shadow: 4px 4px 70.6px 0px #EDEDED40;
}

.card-header {
    @apply space-y-2.5 py-5 px-3 border-b border-[#E0E0E0];
}

.plan-meta {
    @apply space-y-2.5;
}

.plan-price {
    @apply text-[#303030] text-[32px] font-bold;
}

.plan-title {
    @apply text-[#303030] text-2xl font-bold;
}

.plan-description {
    @apply text-[#616161];
}

.benefits {
    @apply flex-1 space-y-6 py-8 px-4;
}

.benefit-point {
    @apply flex items-center gap-4;
}

.benefit-pointdetail {
    @apply text-[#616161] text-sm text-start;
}