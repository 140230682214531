.feature_grid {
    @apply grid sm:grid-cols-4 xl:grid-cols-6 gap-[25px];
}

.feature_grid>.grid_item {
    @apply sm:col-span-2 p-8 rounded-[18px];
}

.feature_grid>.grid_item:last-child:nth-child(3n - 1) {
    @apply xl:col-end-[-2];
}

.feature_grid>.grid_item:nth-last-child(2):nth-child(3n + 1) {
    @apply xl:col-end-4 xl:row-start-3;
}

/*

*** Center elements on last row of grid ***

// Dealing with 2 orphan items

.item:last-child:nth-child(3n - 1) {
    grid-column-end: -2;
}
.item:nth-last-child(2):nth-child(3n + 1) {
    grid-column-end: 4;
}

// Dealing with single orphan

.item:last-child:nth-child(3n - 2) {
    grid-column-end: 5;
}
*/