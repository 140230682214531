#richtext-wrapper .ck-editor .ck-sticky-panel .ck-sticky-panel__content,
#richtext-wrapper .ck-editor .ck-sticky-panel .ck-sticky-panel__content .ck-toolbar {
    @apply border-versich-border rounded-t-lg;
}

#richtext-wrapper .ck-editor__main .ck-content.ck-editor__editable {
    @apply min-h-40 border-versich-border rounded-b-lg;
}

._error>#richtext-wrapper .ck-editor .ck-sticky-panel .ck-sticky-panel__content,
._error>#richtext-wrapper .ck-editor .ck-sticky-panel .ck-sticky-panel__content .ck-toolbar,
._error>#richtext-wrapper .ck-editor__main .ck-content.ck-editor__editable {
    @apply border-red-500;
}