body {
    margin: 0;
    font-family: "Montserrat", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.App {
    text-align: center;
}

.max_screen_w {
    max-width: 1600px;
    margin: 0 auto;
}

/* Box shadow */
.inner_shadow {
    box-shadow: -2px -4px 8px 0px rgba(255, 255, 255, 0.35) inset,
        2px 2px 8px 0px rgba(255, 255, 255, 0.35) inset;
}

/* Button */
.primary_btn {
    @apply bg-versich-light-blue hover:bg-versich-blue-hover text-white font-medium px-5 md:px-6 py-2 md:py-2.5 inner_shadow rounded-xl transition-colors;
}

.primary_btn_mobile_link {
    @apply sm401:bg-versich-light-blue sm401:hover:bg-versich-blue-hover text-versich-darktext-color max-sm401:hover:text-versich-blue-hover sm401:text-white font-medium sm401:px-6 py-2 inner_shadow rounded-xl transition-colors;
}

.secondary_btn {
    @apply text-versich-darktext-color hover:text-versich-blue font-medium px-5 lg:px-6 py-2 border border-versich-darktext-color/20 hover:border-versich-blue/60 rounded-xl transition-colors;
}

.accent_btn {
    @apply bg-versich-lighter-blue hover:bg-versich-blue text-white font-medium px-5 md:px-6 py-2 md:py-2.5 rounded-xl transition-colors;
}

form input.empty,
form textarea.empty {
    border-color: rgb(248 113 113);
}

.render_richtext img {
    @apply max-w-full h-auto;
}

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@400;500;700&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap")